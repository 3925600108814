import {
  SAVE_SETUP_CHANGES,
  GET_BUSINESS_DETAILS,
  UPLOAD_BUSINESS_LOGO,
  GETTING_BUSINESS_DETAILS,
  SAVING_SETUP_CHANGES,
  REQUEST_CHANGE_USER_PASSWORD_STATUS,
  RESET_USER_PASSWORD_STATUS,
  CHANGE_USER_PASSWORD_STATUS,
  CHANGE_BUSINESS_DETAILS,
  GETTING_BUSINESS_COMPLIANCE_DETAILS,
  SET_COMPLIANCE_INFO,
} from "./SignupActions";

const initialState = {
  isFetchingBusinessSettings: false,
  isFetchingBusinessComplianceSettings: false,
  isUpdatingBusinessSettings: false,
  isBusinessDataLoaded: false,
  business: {
    id: "",
    address: "",
    email: "",
    description: "",
    vertical: "",
    websites: "",
  },
  complianceInfo: {},
  isUploadingBusinessLogo: false,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVING_SETUP_CHANGES:
      return {
        ...state,
        isUpdatingBusinessSettings: action.isUpdatingBusinessSettings,
      };

    case CHANGE_BUSINESS_DETAILS:
      return {
        ...state,
        business: {
          ...state.business,
          ...action.business,
        },
      };

    case SET_COMPLIANCE_INFO:
      return {
        ...state,
        complianceInfo: {
          ...state.complianceInfo,
          ...action.payload,
        },
      };

    case CHANGE_USER_PASSWORD_STATUS:
      return {
        ...state,
        isChangingPassword: action.isChangingPassword,
      };

    case RESET_USER_PASSWORD_STATUS:
      return {
        ...state,
        isResetingPassword: action.isResetingPassword,
      };

    case REQUEST_CHANGE_USER_PASSWORD_STATUS:
      return {
        ...state,
        isRequestingChangePassword: action.isRequestingChangePassword,
      };

    case SAVE_SETUP_CHANGES:
      return {
        ...state,
        isUpdatingBusinessSettings: action.isUpdatingBusinessSettings,
      };

    case GETTING_BUSINESS_DETAILS:
      return {
        ...state,
        isFetchingBusinessSettings: action.isFetchingBusinessSettings,
      };

    case GETTING_BUSINESS_COMPLIANCE_DETAILS: {
      return {
        ...state,
        isFetchingBusinessComplianceSettings: action.payload,
      };
    }

    case GET_BUSINESS_DETAILS:
      return {
        ...state,
        business: {
          ...state.business,
          ...action.business,
        },
        isBusinessDataLoaded: action.isBusinessDataLoaded,
        isFetchingBusinessSettings: action.isFetchingBusinessSettings,
      };

    case UPLOAD_BUSINESS_LOGO:
      return {
        ...state,
        isUploadingBusinessLogo: action.isUploadingBusinessLogo,
      };

    default:
      return { ...state };
  }
};

export default signupReducer;

export const getBusinessFetchingState = (state) =>
  state.signup.isFetchingBusinessSettings;
export const getBusinessSettingsState = (state) =>
  state.signup.isUpdatingBusinessSettings;
export const getBusinessSettings = (state) => state.signup.business;
export const getComplianceInfo = (state) => state.signup.complianceInfo;
export const getBusinessSettingsId = (state) =>
  state.signup.business && state.signup.business.id
    ? state.signup.business.id
    : "";
export const getBusinessLogoUploadingState = (state) =>
  state.signup.isUploadingBusinessLogo;
export const getChangePasswordStatus = (state) =>
  state.signup.isChangingPassword;
export const getResetPasswordStatus = (state) =>
  state.signup.isResetingPassword;
export const getPasswordChangeRequestStatus = (state) =>
  state.signup.isRequestingChangePassword;
export const getBusinessLoadedState = (state) =>
  state.signup.isBusinessDataLoaded;
