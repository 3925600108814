import React from "react";
import replaceAll from "replaceall";

/**
 * This HOC will ensure that if any built in method
 * in predefined classes fallback we can handle it here
 * by defining the same function with same signature.
 */
function withPolyfill(WrappedComponent) {
  return (props) => {
    // String replaceAll function override
    // to make sure if replaceAll is not in string class it should
    // still work as it is
    if (!String.prototype.replaceAll) {
      // eslint-disable-next-line no-extend-native
      String.prototype.replaceAll = function (findText, replaceText) {
        return replaceAll(findText, replaceText, this);
      };
    }

    return <WrappedComponent {...props} />;
  };
}

export default withPolyfill;
