import mapKeys from "lodash/mapKeys";

import {
  UPDATE_IMAGE_VIEWER_DETAILS,
  CLOSE_IMAGE_VIEWER,
  GET_IMAGE_VIEWER_LIST,
  FILL_INITIAL_DATA_IMAGE_VIEWER,
} from "./ImageViewerType";

const initialState = {
  showViewer: false,
  activeConversationId: null,
  data: {
    id: null,
    src: null,
    messageType: null,
    date: null,
    name: null,
    contentType: null,
  },
  dataList: {},
};

const ImageViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_IMAGE_VIEWER_DETAILS:
      return {
        ...state,
        showViewer: true,
        data: action.payload,
      };

    case CLOSE_IMAGE_VIEWER:
      return {
        ...state,
        showViewer: false,
        data: initialState.data,
      };

    case FILL_INITIAL_DATA_IMAGE_VIEWER: {
      const dataList = action.payload.data.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      );

      return {
        ...state,
        dataList: {
          ...mapKeys(dataList, "id"),
        },
        activeConversationId: action.payload.activeConversationId,
      };
    }
    case GET_IMAGE_VIEWER_LIST: {
      const dataList = [
        ...action.payload.data,
        ...Object.values(state.dataList),
      ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      return {
        ...state,
        dataList: { ...mapKeys(dataList, "id") },
      };
    }

    default:
      return state;
  }
};

export default ImageViewerReducer;
