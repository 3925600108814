export const colors = [
  "#FBB6D7",
  "#DD6161",
  "#EBB64E",
  "#F576D2",
  "#27C8FB",
  "#F73B73",
  "#C0AAFF",
  "#F3885A",
  "#A6F441",
  "#A282FF",
  "#FFFA86",
  "#54FDAC",
  "#31A0F0",
  "#40FBD9",
  "#FAD2AD",
];

export default colors;
