import { useState, useContext, useLayoutEffect } from "react";
import { ThemeContext } from "styled-components";
import useEventListener from "./useEventListener";

const dirMap = {
  down: "max-width",
  up: "min-width",
};

/**
 * Use this hook to get if the query matches or not
 * @param {ViewportModes} mode Viewport modes
 * @param {"down" | "up"} dir down => max-width, up => min-width
 * @returns {boolean}
 */
export default function useMediaQuery(mode = "desktopL", dir = "down") {
  const theme = useContext(ThemeContext);
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState(null);

  const mediaQuery = `(${dirMap[dir]}:${theme.breakpoints[mode]})`;

  /**
   * ? Why useLayoutEffect => isMatch changes the rendering conditionally.
   * ? useLayoutEffect runs immediately after the DOM has been updated,
   * ? but before the browser has had a chance to "paint" those changes.
   * ? This eliminates the flash of component
   */
  useLayoutEffect(() => {
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", (e) => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}
