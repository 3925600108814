import React from "react";
import packageJson from "./meta.json";
import {
  loadInteraktRefreshCount,
  saveInteraktRefreshCount,
} from "./util/kiwiCookiesHandler";

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
    };
  }

  componentDidMount() {
    this.checkAndRefresh();
  }

  clearCache = () => {
    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    let interakt_version_load = loadInteraktRefreshCount();
    if (interakt_version_load && parseInt(interakt_version_load) > 0) {
    } else {
      window.location.reload(true);
    }
  };

  checkAndRefresh = () => {
    fetch(`/meta.json?v=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion,
        );
        if (shouldForceRefresh) {
          let interakt_version_load = loadInteraktRefreshCount();
          if (interakt_version_load) {
            interakt_version_load = parseInt(interakt_version_load) + 1;
            saveInteraktRefreshCount(interakt_version_load);
            this.setState({
              loading: false,
              isLatestVersion: true,
            });
          } else {
            saveInteraktRefreshCount(1);
            this.setState({
              loading: false,
              isLatestVersion: false,
            });
          }
        } else {
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch(() => {});
  };

  render() {
    const { loading, isLatestVersion } = this.state;
    const refreshCacheAndReload = this.clearCache;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}

export default CacheBuster;
