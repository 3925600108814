import { APP_RETRY_SECONDS } from "constants/enums/appEnums";

export default function componentLoader(lazyComponent, attemptsLeft) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // let us retry after 1500 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(
                        resolve,
                        reject,
                    );
                }, APP_RETRY_SECONDS);
            });
    });
}
