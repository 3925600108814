import React from "react";
import styled, { keyframes } from "styled-components";
import { color, space, layout } from "styled-system";

const spin = keyframes`
100% {
    transform: rotate(360deg);
  }
`;

// todo - add more spinner color once design system is integrated
const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  margin: 0;
  ${color}
  ${space}
  ${layout}

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
  }

  &:before {
    animation: ${spin} 1s 1s linear infinite;
  }

  &:after {
    border-bottom: 2px solid currentColor;
    animation: spin 0.8s ease-in infinite;
  }
`;

const Loader = (props) => <Spinner {...props} />;

Loader.defaultProps = {
  color: "white",
};

export default Loader;
