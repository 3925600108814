import { useState, useEffect, useRef } from "react";

const useFacebookSDK = (appId) => {
  const [isSDKLoaded, setIsSDKLoaded] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    // Function to load the Facebook SDK
    const loadSDK = () => {
      return new Promise((resolve) => {
        if (document.getElementById("facebook-jssdk")) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.id = "facebook-jssdk";
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.onload = resolve;
        document.body.appendChild(script);
      });
    };

    // Function to initialize the SDK with the given appId
    const initializeSDK = () => {
      if (window.FB) {
        window.FB.init({
          appId: appId,
          cookie: true,
          xfbml: true,
          version: "v9.0",
        });
        if (isMounted.current) {
          setIsSDKLoaded(true);
        }
      }
    };

    // Load and initialize SDK
    loadSDK().then(() => {
      if (window.FB) {
        initializeSDK();
      } else {
        window.fbAsyncInit = initializeSDK;
      }
    });

    // Cleanup function
    return () => {
      isMounted.current = false;
      // Reset the fbAsyncInit function
      window.fbAsyncInit = undefined;
      // Unset the global FB object
      if (window.FB) {
        window.FB = undefined;
      }
      // Remove the SDK script if it exists
      const script = document.getElementById("facebook-jssdk");
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [appId]);

  return isSDKLoaded;
};

export default useFacebookSDK;
