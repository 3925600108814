import React, { useState, useEffect } from "react";
import { FormContainer } from "CommonStyle/CommonStyles";
import Box from "konnekt/Box";
import DropDownControlled from "konnekt/Dropdown";
import Label from "konnekt/Label";
import TextInput from "konnekt/TextInput";
import styled from "styled-components";
import Button from "konnekt/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getCollegeList, getSourceList, sendLead } from "modules/Inbox/inboxStateActions";
import ErrorMessage from "konnekt/ErrorMessage";
import isEmail from "validator/lib/isEmail";

const Container = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LabelContainer = styled(Label)`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1.2rem 0 0.5rem 0;
  sup {
    color: #ff0000;
    font-size: 1.2rem;
  }
`;

const SendLeadModal = ({ activeCustomer, handleCloseModal, activeProject}) => {
  const dispatch = useDispatch();
  const campaignsData = useSelector((state) => state?.thread?.campaignsData);
  const [errors, setErrors] = useState({});
  const [collegeList, setCollegeList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [selectedClgId, setSelectedClgId] = useState(null);
  const [inputValue, setInputValue] = useState({
    college: "",
    lead_type: "CSL",
    name: activeCustomer?.contact_name ? activeCustomer.contact_name : "",
    email: activeCustomer?.contact_email ? activeCustomer.contact_email : "",
    phone: activeCustomer?.contact_phone ? activeCustomer.contact_phone : "",
    gender: "",
    source: campaignsData.length > 0 ? `wp_${campaignsData.slice(-1)[0]?.name}` : "",
  });

  useEffect(() => {
    const getData = async () => {
      if (activeCustomer?._id) {
        const getClg = await dispatch(getCollegeList(activeProject));
        setCollegeList(getClg);
        if (!campaignsData.length > 0) {
          const getSource = await dispatch(getSourceList(activeProject));
          setSourceList(getSource);
        }
      }
    };
    getData();
  }, []);

  const getGenderOption = () => {
    return [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
    ];
  };
  const getLeadOption = () => {
    return [
      { label: "CSL", value: "CSL" },
      { label: "CLL", value: "CLL" },
    ];
  };

  const getCollegeOption = () => {
    return (
      collegeList &&
      collegeList?.map((item) => ({
        label: item?.clg_name,
        value: item?.clg_name,
        id: item?.clg_id.toString(),
      }))
    );
  };
  const getSourceOption = () => {
    return (
      sourceList &&
      sourceList?.map((item) => ({
        label: item?.name,
        value: item?.source,
      }))
    );
  };
  const validateForm = () => {
    const newErrors = {};

    if (!inputValue.name) {
      newErrors.name = "Name is required";
    }
    if (!inputValue.email || !isEmail(inputValue.email)) {
      newErrors.email = "Valid email is required";
    }
    if (!inputValue.phone) {
      newErrors.phone = "Phone number is required";
    }
    if (!inputValue.source) {
      newErrors.source = "Source is required";
    }
    if (!inputValue.lead_type) {
      newErrors.lead_type = "Lead type is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleChange = (keyName, keyValue) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [keyName]: keyValue,
    }));
    setErrors({ ...errors, [keyName]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();
    if (isFormValid) {
      const data = { ...inputValue };
      data.father_name = "";
      data.state = "";
      data.city = "";
      data.course = "";
      data.specialization = "";
      data.utm_source = "WhatsApp";
      data.utm_medium = "";
      data.utm_campaign = "";
      data.clg_id = selectedClgId;
      const res = await dispatch(sendLead(data,activeProject));
      if (res?.success) {
        handleCloseModal();
      }
    }
  };
  return (
    <Box p="0 0 2rem">
      <FormContainer>
        <LabelContainer>Select College</LabelContainer>
        <DropDownControlled
          selectedValue={inputValue.college}
          height="44px"
          variant="lighterGrey"
          options={getCollegeOption()}
          width="100%"
          allowSearch
          mb="1rem"
          onOptionClick={(item) => {
            handleChange("college", item.label);
            setSelectedClgId(item.id);
          }}
        />
        <Container>
          <Box width="47%">
            <LabelContainer>
              Name<sup>*</sup>
            </LabelContainer>
            <Box>
              <TextInput
                type="text"
                onChange={(value) => handleChange("name", value)}
                value={activeCustomer?.contact_name ? inputValue.name : inputValue.name}
                width="100%"
                bodyTextLimit={40}
                placeholder="Enter Name"
                border="1px solid rgba(0,0,0,.08)"
                height="44px"
              />
            </Box>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Box>
          <Box width="47%">
            <LabelContainer>
              Email<sup>*</sup>
            </LabelContainer>
            <Box>
              <TextInput
                type="text"
                onChange={(value) => handleChange("email", value)}
                value={activeCustomer?.contact_email ? activeCustomer.contact_email : inputValue.email}
                width="100%"
                bodyTextLimit={40}
                disabled={activeCustomer?.contact_email}
                placeholder="Enter Email"
                border="1px solid rgba(0,0,0,.08)"
                height="44px"
                style={{
                  cursor: activeCustomer?.contact_email ? "not-allowed" : "default",
                }}
              />
            </Box>
            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </Box>
        </Container>

        <Container>
          <Box width="47%">
            <LabelContainer>
              Phone<sup>*</sup>
            </LabelContainer>
            <Box>
              <TextInput
                type="text"
                onChange={(e) => handleChange(e)}
                value={activeCustomer?.contact_phone ? activeCustomer.contact_phone : inputValue.phone}
                width="100%"
                bodyTextLimit={10}
                disabled={activeCustomer?.contact_phone}
                placeholder="Enter Phone Number"
                border="1px solid rgba(0,0,0,.08)"
                height="44px"
                style={{
                  cursor: activeCustomer?.contact_phone ? "not-allowed" : "default",
                }}
              />
            </Box>
            {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
          </Box>
          <Box width="47%">
            <LabelContainer>
              Lead Type<sup>*</sup>
            </LabelContainer>
            <Box>
              <DropDownControlled
                selectedValue={inputValue?.lead_type}
                height="44px"
                variant="lighterGrey"
                options={getLeadOption()}
                width="100%"
                mb="1rem"
                onOptionClick={(item) => handleChange("lead_type", item.value)}
              />
            </Box>
            {errors.lead_type && <ErrorMessage>{errors.lead_type}</ErrorMessage>}
          </Box>
        </Container>

        <Container>
          <Box width="47%">
            <LabelContainer>
              Source<sup>*</sup>
            </LabelContainer>
            {campaignsData.length > 0 ? (
              <Box>
                <TextInput
                  type="text"
                  onChange={(e) => handleChange(e)}
                  value={inputValue.source}
                  width="100%"
                  disabled={!!campaignsData}
                  placeholder="Enter Phone Number"
                  border="1px solid rgba(0,0,0,.08)"
                  height="44px"
                  style={{
                    cursor: campaignsData ? "not-allowed" : "default",
                  }}
                />
              </Box>
            ) : (
              <Box>
                <DropDownControlled
                  selectedValue={inputValue.source}
                  height="44px"
                  variant="lighterGrey"
                  options={getSourceOption()}
                  width="100%"
                  allowSearch
                  mb="1rem"
                  onOptionClick={(item) => handleChange("source", item.value)}
                />
              </Box>
            )}
            {errors.source && <ErrorMessage>{errors.source}</ErrorMessage>}
          </Box>
          <Box width="47%">
            <LabelContainer>Gender</LabelContainer>
            <Box>
              <DropDownControlled
                selectedValue={inputValue?.gender}
                height="44px"
                variant="lighterGrey"
                options={getGenderOption()}
                width="100%"
                mb="1rem"
                onOptionClick={(item) => handleChange("gender", item.value)}
              />
            </Box>
          </Box>
        </Container>
      </FormContainer>
      <Box textAlign="right">
        <Button marginLeft="auto" onClick={handleSubmit}>
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default SendLeadModal;
