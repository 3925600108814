import React from "react";

function commerceV2({ fill, ...props }) {
  return (
    <svg
      {...props}
      viewBox="0 0 33 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5262 22.8533L25.1262 17.0267C24.8328 15.8267 23.7928 15 22.5795 15H4.05951C2.85951 15 1.80618 15.84 1.52618 17.0267L0.126181 22.8533C-0.193819 24.2133 0.0995142 25.6 0.952847 26.6933C1.05951 26.84 1.20618 26.9467 1.32618 27.08V36.3333C1.32618 37.8 2.52618 39 3.99285 39H22.6595C24.1262 39 25.3262 37.8 25.3262 36.3333V27.08C25.4462 26.96 25.5928 26.84 25.6995 26.7067C26.5528 25.6133 26.8595 24.2133 26.5262 22.8533ZM22.5395 17.6533L23.9395 23.48C24.0728 24.04 23.9528 24.6 23.6062 25.04C23.4195 25.28 23.0195 25.6667 22.3528 25.6667C21.5395 25.6667 20.8328 25.0133 20.7395 24.1467L19.9662 17.6667L22.5395 17.6533ZM14.6595 17.6667H17.2728L17.9928 23.6933C18.0595 24.2133 17.8995 24.7333 17.5528 25.12C17.2595 25.4667 16.8328 25.6667 16.2862 25.6667C15.3928 25.6667 14.6595 24.88 14.6595 23.92V17.6667ZM8.64618 23.6933L9.37951 17.6667H11.9928V23.92C11.9928 24.88 11.2595 25.6667 10.2728 25.6667C9.81951 25.6667 9.40618 25.4667 9.08618 25.12C8.75285 24.7333 8.59285 24.2133 8.64618 23.6933ZM2.71285 23.48L4.05951 17.6667H6.68618L5.91285 24.1467C5.80618 25.0133 5.11285 25.6667 4.29951 25.6667C3.64618 25.6667 3.23285 25.28 3.05951 25.04C2.69951 24.6133 2.57951 24.04 2.71285 23.48ZM3.99285 36.3333V28.2933C4.09951 28.3067 4.19285 28.3333 4.29951 28.3333C5.45951 28.3333 6.51285 27.8533 7.28618 27.0667C8.08618 27.8667 9.15285 28.3333 10.3662 28.3333C11.5262 28.3333 12.5662 27.8533 13.3395 27.0933C14.1262 27.8533 15.1928 28.3333 16.3928 28.3333C17.5128 28.3333 18.5795 27.8667 19.3795 27.0667C20.1528 27.8533 21.2062 28.3333 22.3662 28.3333C22.4728 28.3333 22.5662 28.3067 22.6728 28.2933V36.3333H3.99285Z"
        fill={fill}
      />
      <path
        d="M29 8L30.25 5.25L33 4L30.25 2.75L29 0L27.75 2.75L25 4L27.75 5.25L29 8Z"
        fill="#FDB022"
      />
      <path
        d="M29 14L27.75 16.75L25 18L27.75 19.25L29 22L30.25 19.25L33 18L30.25 16.75L29 14Z"
        fill="#FDB022"
      />
      <path
        d="M21.5 8.5L19 3L16.5 8.5L11 11L16.5 13.5L19 19L21.5 13.5L27 11L21.5 8.5ZM19.99 11.99L19 14.17L18.01 11.99L15.83 11L18.01 10.01L19 7.83L19.99 10.01L22.17 11L19.99 11.99Z"
        fill="#FDB022"
      />
    </svg>
  );
}

export default commerceV2;
