import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import Icon from "konnekt/Icon/Icon";
import Box from "konnekt/Box";
import { useSelector, useDispatch } from "react-redux";
import useOutsideClick from "../../customHooks/useOutsideClick";
import { logOutUser } from "../App/AppActions";
import {
  HeaderBg,
  HeaderSection,
  BackgroundSection,
  MobileHeader,
  MobileProfileVisual,
  ProfileBox,
  ProfileDetailsHading,
  ProfileHading,
  DropDownBox,
  ItemDropdown,
  ContactName,
} from "./Header.styles";

const SettingHeader = ({ HeaderData, manageData, adsData, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profileDetail = useSelector((state) => state?.profile?.activeAgent?.fetchUpdateAgent?.agent);

  const location = useLocation();
  const [isHamburgerOpened, setIsHamburgerOpened] = useState(false);
  const [isManageExpand, setIsManageExpand] = useState(false);

  const user = useSelector((state) => state.app.user);

  const [profileOpen, setProfileOpen] = useState(false);
  const btnRef = useRef();

  useEffect(() => {
    const dropdownClose = (e) => {
      if (e.button !== btnRef.current) {
        setProfileOpen(false);
      }
    };
    document.body.addEventListener("click", dropdownClose);
    return () => document.body.removeEventListener("click", dropdownClose);
  }, []);

  const redirectLink = (link) => {
    window.open(link, "_self");
  };
  const handleDropdown = () => {
    setProfileOpen(!profileOpen);
  };

  const logoutHandle = () => {
    dispatch(logOutUser());
  };

  const setInitialValue = () => {
    setIsHamburgerOpened(false);
  };

  const ref = useOutsideClick(() => setInitialValue());

  const login_via = typeof window === "undefined" ? "" : localStorage.getItem("login_via");
  const is_super_admin = typeof window === "undefined" ? "" : localStorage.getItem("is_super_admin");
  const appName = login_via === "admin" ? (is_super_admin == "true" ? "super-admin" : "admin") : "agent";
  const handleBoxClick = () => {
    if (appName === "super-admin") {
      history.push("/super-admin/business-dashboard");
    } else if (appName === "admin") {
      history.push("/admin/project-dashboard");
    } else {
      history.push("/project-dashboard");
    }
  };
  // const isManage
  return (
    <HeaderBg ref={ref}>
      <HeaderSection hide={location.pathname !== "/project-dashboard"}>
        <Box onClick={handleBoxClick} width={{ _: "11rem", mobile: "15rem" }}>
          <img src="/images/automate-logo.svg" alt="" />
        </Box>

        {location.pathname !== "/project-dashboard" && (
          <ul>
            {HeaderData &&
              HeaderData.map((item) => (
                <>
                  <li className="nav-item">
                    <NavLink
                      to={item.link}
                      activeClassName="sideBarActive"
                      style={{
                        background: location.pathname.search(item.link) > -1 && "#5e3ec533",
                        borderRadius: location.pathname.search(item.link) > -1 && ".4rem",
                        padding: "1rem",
                      }}
                    >
                      <Icon iconName={item.icon} iconSize="xs" />
                      <h6
                        style={{
                          marginBottom: location.pathname === item?.link && "0",
                        }}
                      >
                        {item.name}{" "}
                      </h6>
                    </NavLink>
                  </li>
                </>
              ))}
          </ul>
        )}
        <Box display="flex" alignItems="center" position="relative">
          <Box>
            <ContactName>{user?.first_name}</ContactName>
          </Box>
          <ProfileBox className="place-items-center" onClick={handleDropdown}>
            <ProfileDetailsHading>{profileDetail?.first_name ? profileDetail?.first_name?.[0] : user?.first_name?.[0]}</ProfileDetailsHading>
            <ProfileDetailsHading>{profileDetail?.last_name ? profileDetail?.last_name?.[0] : user?.last_name?.[0]}</ProfileDetailsHading>
          </ProfileBox>
          <DropDownBox data={profileOpen} ref={btnRef}>
            {login_via !== "admin" && (
              <ItemDropdown
                className="flex-center"
                onClick={() => {
                  window.location.replace("/profile");
                }}
              >
                <h5>Profile Page</h5>
              </ItemDropdown>
            )}
            <ItemDropdown onClick={() => logoutHandle()}>
              <h5>Logout</h5>
            </ItemDropdown>
          </DropDownBox>
        </Box>
      </HeaderSection>
      {location.pathname !== "/project-dashboard" && (
        <MobileHeader>
          {isHamburgerOpened ? (
            <Box>
              <BackgroundSection>
                <MobileProfileVisual>
                  <NavLink to={"/profile"}>
                    <ProfileBox className="place-items-center" onClick={() => setIsHamburgerOpened(!isHamburgerOpened)}>
                      <ProfileDetailsHading>{profileDetail?.first_name?.[0]}</ProfileDetailsHading>
                      <ProfileDetailsHading>{profileDetail?.last_name?.[0]}</ProfileDetailsHading>
                    </ProfileBox>
                  </NavLink>
                  <ProfileHading>{user?.contact_name}</ProfileHading>
                  <Icon
                    cursor="pointer"
                    iconName="close"
                    width="2rem"
                    position="absolute"
                    right="1.7rem"
                    onClick={() => setIsHamburgerOpened(!isHamburgerOpened)}
                  />
                </MobileProfileVisual>

                <ul>
                  {HeaderData &&
                    HeaderData.map((item) => (
                      <>
                        <li className="nav-item">
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box onClick={() => setIsHamburgerOpened(!isHamburgerOpened)}>
                              <NavLink to={item.link} activeClassName="sideBarActive">
                                <Icon iconName={item.icon} iconSize="xs" />
                                <h6>{item.name}</h6>
                              </NavLink>
                            </Box>
                            {!!item?.isExpand && (
                              <Box px="3rem" py=".5rem" onClick={(e) => setIsManageExpand(!isManageExpand)}>
                                <Icon iconName="downArrow" transform={isManageExpand ? "none" : "rotate(-180deg)"} />
                              </Box>
                            )}
                          </Box>
                        </li>
                      </>
                    ))}
                  {!!isManageExpand && (
                    <Box ml="1.5rem" height="40%" overflowY="scroll">
                      {!!isManageExpand &&
                        manageData &&
                        manageData.map((item) => (
                          <>
                            <li className="nav-item" onClick={() => setIsHamburgerOpened(!isHamburgerOpened)}>
                              <NavLink to={item.link} activeClassName="sideBarActive">
                                <Icon iconName={item.icon} iconSize="xs" color="primary" fill="primary" />
                                <h6>{item.name}</h6>
                              </NavLink>
                            </li>
                          </>
                        ))}
                    </Box>
                  )}
                </ul>
              </BackgroundSection>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" paddingTop="1rem" position="absolute" width="100%">
              <div className="bars-open" onClick={() => setIsHamburgerOpened(!isHamburgerOpened)}>
                <Icon cursor="pointer" marginRight="1.5rem" iconName="bar" width="4rem" />
              </div>
              <Box display="flex" position="relative" left="-1.5rem" width="100%" justifyContent="center" onClick={handleBoxClick}>
                <img src="/images/automate-logo.svg" alt="" />
              </Box>
            </Box>
          )}
        </MobileHeader>
      )}
    </HeaderBg>
  );
};

export default SettingHeader;
