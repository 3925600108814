import createToast from "../../components/Toasts/createToast";

export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export function addToast(msg, msgtype, timer = 4) {
  return {
    payload: createToast({
      msg,
      msgtype,
      timer,
    }),
    type: ADD_TOAST,
  };
}

export function removeToast(id) {
  return {
    payload: id,
    type: REMOVE_TOAST,
  };
}
