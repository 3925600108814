import styled from "styled-components";
import Box from "konnekt/Box";
export const HeaderBg = styled(Box)`
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.09);
  position: fixed;
  top: 0;
  z-index: 101;
  width: 100%;
  background-color: var(--white);
`;

export const HeaderSection = styled.div`
  align-items: center;
  padding: 0.5rem 3.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  h6 {
    margin: 0 0 0 0.3rem;
    font-size: 1.3rem;
  }

  ul {
    display: flex;
  }

  li {
    padding: 1rem 1rem;
  }

  a {
    display: flex;
    color: rgba(13, 20, 192, 0.7);
    text-decoration: none;
    font-size: 1.3rem;

    svg {
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  img {
    width: 100%;
    max-height: 3.4rem;
    object-fit: contain;
  }

  h5 {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize;
  }

  p {
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 767px) {
    display: ${(props) => (props.hide ? "none" : "flex")};
    justify-content: space-between;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 1.5rem;
    img {
      width: 11rem;
    }

    h5 {
      font-size: 1rem;
    }

    p {
      display: none;
    }
    li {
      padding: 1rem 0rem;
    }
  }
`;
export const BackgroundSection = styled.div`
  background-color: var(--white);
  height: 100vh;
  padding: 0 2rem;
  overflow-y: auto;
`;
export const MobileHeader = styled.div`
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
    background-color: var(--white);
    padding: 1.5rem 0;
    height: 7rem;

    ul {
      padding-left: 0;
      background-color: var(--white);
    }

    li {
      list-style: none;
      padding: 1.5rem 0;
    }

    img {
      width: 15rem;
    }

    a {
      display: flex;
      align-items: center;
    }

    h6 {
      margin-left: 1.5rem;
      font-size: 1.4rem;
    }
  }
`;
export const MobileProfileVisual = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5rem;
`;
export const ProfileBox = styled(Box)`
  width: 4.5rem;
  height: 4.5rem;
  background: var(--blue-l1);
  border-radius: 5rem;
  text-transform: capitalize;
  @media only screen and (max-width: 480px) {
    width: 3.5rem;
    height: 3.5rem;
  }
`;
export const ProfileDetailsHading = styled(Box)`
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--black);
  cursor: inherit;
`;
export const ProfileHading = styled(Box)`
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 0.01rem;
  margin-left: 0.4rem;
`;

export const DropDownBox = styled(Box)`
  border: 0.1rem solid #0002;
  border-radius: 0.7rem;
  padding: 1rem 0;
  position: absolute;
  margin-top: 15rem;
  display: block;
  background: var(--white);
  width: 15rem;
  right: 0;

  display: ${(props) => (props.data ? "block" : "none")};
`;

export const ItemDropdown = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background-color: ${(props) => (props.isDisabled ? "none" : "var(--blue)")};
    color: var(--white);
  }
`;

export const ContactName = styled.h5`
  margin-right: 0.5rem !important;
  @media only screen and (max-width: 425px) {
    font-size: 1rem !important;
  }
`;
