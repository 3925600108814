import reactCookie from "react-cookies";
import moment from "moment";

export function setLeadTrackContent(input) {
  const date = moment().add(1, "day");
  const stringInput = JSON.stringify(input);
  reactCookie.save("leadtrack", stringInput, {
    path: "/",
    maxAge: date.diff(moment(), "seconds"),
    expires: date.toDate(),
  });
}

export function loadLeadTrackContent() {
  return reactCookie.load("leadtrack");
}

export function saveInteraktRefreshCount(count) {
  const date = moment().add(30, "minutes");
  const stringInput = JSON.stringify(count);
  reactCookie.save("interakt_version_load", stringInput, {
    path: "/",
    maxAge: date.diff(moment(), "seconds"),
    expires: date.toDate(),
  });
}

export function loadInteraktRefreshCount() {
  return reactCookie.load("interakt_version_load");
}

