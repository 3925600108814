import { showNotification } from "util/utility";
import {
  UPDATE_ACTIVE_SMART_CARD,
  UPDATE_PERSONAL_DETAILS_DATA,
  SHOW_SEARCH_SECTION,
  CLOSE_SEARCH_SECTION,
  SET_SEARCH_ACTIVE_CONVERSATION_ID,
  IS_FINDING_SEARCH_MESSAGE,
  CHANGE_SANDBOX_MODAL_VISIBILITY,
  CHANGE_SPAM_MODAL_VISIBILITY,
  SET_MULTI_SELECT_CHATS,
  RESET_MULTI_SELECT_STATE,
  ENABLE_MULTI_SELECT_STATE,
} from "./inboxStateTypes";

export const updateActiveSmartCard = (data) => ({
  type: UPDATE_ACTIVE_SMART_CARD,
  payload: data,
});

export const updatePersonalDetailsData = (data) => ({
  type: UPDATE_PERSONAL_DETAILS_DATA,
  payload: data,
});

export const showSearchSection = () => ({ type: SHOW_SEARCH_SECTION });

export const closeSearchSection = () => ({ type: CLOSE_SEARCH_SECTION });

export const setActiveSearchConversationId = (payload) => ({
  type: SET_SEARCH_ACTIVE_CONVERSATION_ID,
  payload,
});

export const isFindingSearchMessageAction = (payload) => {
  return {
    type: IS_FINDING_SEARCH_MESSAGE,
    payload,
  };
};

export const changeSanboxModalVisibility = (flag) => {
  return {
    type: CHANGE_SANDBOX_MODAL_VISIBILITY,
    payload: flag,
  };
};

export const changeSpamModalVisibility = (flag) => ({
  type: CHANGE_SPAM_MODAL_VISIBILITY,
  payload: flag,
});

export function setMultiSelectChats(chats) {
  return (dispatch) =>
    dispatch({
      type: SET_MULTI_SELECT_CHATS,
      payload: chats,
    });
}

export function resetMultiSelect() {
  return (dispatch) =>
    dispatch({
      type: RESET_MULTI_SELECT_STATE,
    });
}
export function enableMultiSelect() {
  return (dispatch) =>
    dispatch({
      type: ENABLE_MULTI_SELECT_STATE,
    });
}

// const CRM_API = process.env.REACT_APP_CRM_API_HOST;
// const CRM_SECRET_KEY = process.env.REACT_APP_CRM_SECRET_KEY;
export const getCollegeList = (activeProjectData) => async () => {
  try {
    const response = await fetch(`${activeProjectData.click_lead_save_url}/api/v1/getPartnerColleges`, {
      method: "GET",
    });
    const data = await response.json();
    return data?.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const getSourceList = (activeProjectData) => async () => {
  try {
    const response = await fetch(`${activeProjectData.click_lead_save_url}/api/v1/getAllSources`, {
      method: "GET",
    });
    const data = await response.json();
    return data?.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const sendLead = (leadData,activeProjectData) => async () => {
  try {
    const data = await fetch(`${activeProjectData.click_lead_save_url}/api/v1/lead/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "secret-key": `${activeProjectData.click_lead_save_url_token}`,
      },
      body: JSON.stringify(leadData),
    });
    const res = await data.json();
    if (res?.success) {
      showNotification("success", res?.message);
    } else {
      showNotification("error", res?.message);
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};
