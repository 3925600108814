import {
  FETCH_CAMPAIGN_LIST_DETAILS,
  FETCH_CAMPAIGN_LIST_DETAIL_SUCCESS,
  FETCH_CAMPAIGN_LIST_FAILURE,
  NEW_CAMPAIGN_DETAILS,
  NEW_CAMPAIGN_DETAILS_SUCCESS,
  NEW_CAMPAIGN_FAILED,
  CAMPAIGN_TEMPLATE_ID,
  CAMPAIGN_BODY_DETAILS,
  CAMPAIGN_TEMPLATE_MESSAGE_ID,
  CLEAR_NEW_CAMPAIGN_TEMPLATE,
  FETCH_CAMPAIGN_STATS_DETAILS,
  FETCH_CAMPAIGN_STATS_FAILURE,
  FETCH_CAMPAIGN_STATS_DETAIL_SUCCESS,
  FETCH_CAMPAIGN_ID_STATS_DETAILS,
  FETCH_CAMPAIGN_ID_STATS_DETAIL_SUCCESS,
  FETCH_CAMPAIGN_ID_STATS_FAILURE,
  FETCH_CAMPAIGN_ID_STATS_USER_DETAILS,
  FETCH_CAMPAIGN_ID_STATS_USER_FAILURE,
  FETCH_CAMPAIGN_ID_STATS_USER_DETAIL_SUCCESS,
  UPDATE_INITIAL_DATA,
  FETCH_CAMPAIGN_CUSTOMER_COUNT,
  UPDATE_SORT_BY,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  FETCH_CAMPAIGN_LIST_EXCEL_SUCCESS,
  FETCH_CAMPAIGN_LIST_EXCEL_FAILURE,
  FETCH_CAMPAIGN_LIST_EXCEL,
} from "./campaignActionType";
import moment from "moment";
import { DEFAULT_PAGINATION_INFO } from "../../constants/const";

const initialState = {
  pageNum: DEFAULT_PAGINATION_INFO.pageNum,
  pageSize: DEFAULT_PAGINATION_INFO.pageSize,
  sortBy: "dsc",
  start_date: null,
  end_date: null,
  isFetchCampaignStats: false,
  campaignStats: {},
  campaignAgentList: {
    campaignsData: [],
    totalCampaignCount: 0,
    isFetchCampaignList: false,
  },
  newCampaign: {
    newCampaignDetail: {},
    isUpdatingCampaign: false,
  },
  template_id: "",
  newCampaignData: {
    project_id: "",
    name: "",
    campaign_type: "",
    target_date: "",
    status: "live",
    attribute_filter: false,
    is_active: true,
    template_id: "",
    template_lang_code: "",
    custom_template_message: "",
    template_name: "",
    template_media_url: "",
    merge_variables_header: [],
    merge_variables_body: [],
    filter_rules: [],
    dateFilters: [],
    optedIn: 2,
    blocked: 2,
    sample_code_variables: [],
    carousel_card: [],
  },
  isCampaignDetailsStats: false,
  campaignDetailsStats: {},
  isCampaignDetailsUserStats: false,
  campaignDetailsUserStats: [],
  campaignCustomerCount: {
    count: null,
    status: "none",
  },
  sending: false,
  error: null,
  response: null,
  excelCampaignListData: {
    data: [],
    status: "none",
  },
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_STATS_DETAILS: {
      return {
        ...state,
        isFetchCampaignStats: action?.isFetchCampaignStats,
      };
    }

    case FETCH_CAMPAIGN_STATS_DETAIL_SUCCESS: {
      return {
        ...state,
        isFetchCampaignStats: action.isFetchCampaignStats,
        campaignStats: action.payload,
      };
    }

    case FETCH_CAMPAIGN_STATS_FAILURE: {
      return {
        ...state,
        isFetchCampaignStats: action.isFetchCampaignStats,
      };
    }

    case FETCH_CAMPAIGN_LIST_DETAILS: {
      return {
        ...state,
        campaignAgentList: {
          isFetchCampaignList: action?.isFetchCampaignList,
        },
      };
    }
    case FETCH_CAMPAIGN_LIST_DETAIL_SUCCESS: {
      return {
        ...state,
        campaignAgentList: {
          campaignsData: action?.payload,
          totalCampaignCount: action?.count || 0,
          isFetchCampaignList: action?.isFetchCampaignList,
        },
      };
    }
    case FETCH_CAMPAIGN_LIST_FAILURE: {
      return {
        ...state,
        campaignAgentList: {
          isFetchCampaignList: action?.isFetchCampaignList,
        },
      };
    }
    case NEW_CAMPAIGN_DETAILS: {
      return {
        ...state,
        newCampaign: {
          isUpdatingCampaign: action?.isUpdatingCampaign,
        },
      };
    }
    case NEW_CAMPAIGN_DETAILS_SUCCESS: {
      return {
        ...state,
        newCampaign: {
          ...state?.newCampaign?.newCampaignDetail,
          newCampaignDetail: action?.payload,
          isUpdatingCampaign: action?.isUpdatingCampaign,
        },
      };
    }
    case NEW_CAMPAIGN_FAILED: {
      return {
        ...state,
        updateCampaign: {
          isUpdatingCampaign: action?.isUpdatingCampaign,
        },
      };
    }

    case CAMPAIGN_TEMPLATE_ID: {
      return {
        ...state,
        template_id: action?.template_id,
      };
    }

    case CAMPAIGN_BODY_DETAILS: {
      // console.log("aciton", action);
      // console.log("state", state.newCampaignData);
      let updatedCarouselCard = state.newCampaignData?.carousel_card || [];

      if (action.keyName === "carousel_card") {
        if (typeof action.keyValue === "function") {
          console.log("inside reducer fun", action.keyValue(updatedCarouselCard));
          updatedCarouselCard = action.keyValue(updatedCarouselCard);
        } else if (Array.isArray(action.keyValue)) {
          updatedCarouselCard = action.keyValue.map((card, index) => {
            if (card === null) {
              return (
                state.newCampaignData?.carousel_card?.[index] || {
                  body_variable: [],
                  button_url_params: "",
                  media_url: "",
                }
              );
            }
            return {
              ...state.newCampaignData?.carousel_card?.[index],
              ...card,
            };
          });
        }
      }
      // if (action.keyName === "carousel_card") {
      //   updatedCarouselCard = action.keyValue.map((card, index) => {
      //     if (card === null) {
      //       return (
      //         state.newCampaignData?.carousel_card?.[index] || {
      //           body_variable: [],
      //           button_url_params: "",
      //           media_url: "",
      //         }
      //       );
      //     }
      //     return {
      //       ...state.newCampaignData?.carousel_card?.[index],
      //       ...card,
      //     };
      //   });
      // }
      return {
        ...state,
        newCampaignData: {
          ...state.newCampaignData,
          project_id: action?.activeProjectId,
          name: action.keyName === "name" ? action.payload ?? action.keyValue : state.newCampaignData?.name,
          campaign_type: action.keyName === "campaign_type" ? action.payload ?? action.keyValue : state.newCampaignData?.campaign_type,
          target_date: action.keyName === "target_date" ? action.payload ?? action.keyValue : state.newCampaignData?.target_date,
          status: action.keyName === "status" ? action.payload ?? action.keyValue : state.newCampaignData?.status,
          attribute_filter: action.keyName === "attribute_filter" ? action.payload ?? action.keyValue : state.newCampaignData?.attribute_filter,
          template_id: action.keyName === "template_id" ? action.payload ?? action.keyValue : state.newCampaignData?.template_id,
          merge_variables_header:
            action.keyName === "merge_variables_header" ? action.payload ?? action.keyValue : state.newCampaignData?.merge_variables_header,
          merge_variables_body: action.keyName === "merge_variables_body" ? action.payload ?? action.keyValue : state.newCampaignData?.merge_variables_body,
          template_media_url: action.keyName === "template_media_url" ? action.payload ?? action.keyValue : state.newCampaignData?.template_media_url,
          template_lang_code: action.keyName === "template_lang_code" ? action.payload ?? action.keyValue : state.newCampaignData?.template_lang_code,
          custom_template_message:
            action.keyName === "custom_template_message" ? action.payload ?? action.keyValue : state.newCampaignData?.custom_template_message,
          filter_rules: action.keyName === "filter_rules" ? action.payload ?? action.keyValue : state.newCampaignData?.filter_rules,
          dateFilters: action.keyName === "dateFilters" ? action.payload ?? action.keyValue : state.newCampaignData?.dateFilters,
          optedIn: action.keyName === "optedIn" ? action.payload ?? action.keyValue : state.newCampaignData?.optedIn,
          blocked: action.keyName === "blocked" ? action.payload ?? action.keyValue : state.newCampaignData?.blocked,
          button_url_params: action.keyName === "button_url_params" ? action.payload ?? action.keyValue : state.newCampaignData?.button_url_params,
          sample_code_variables: action.keyName === "sample_code_variables" ? action.payload ?? action.keyValue : state.newCampaignData?.sample_code_variables,
          carousel_card: action.keyName === "carousel_card" ? updatedCarouselCard : state.newCampaignData?.carousel_card,
        },
      };
    }
    case CAMPAIGN_TEMPLATE_MESSAGE_ID: {
      return {
        ...state,
        newCampaignData: {
          ...state?.newCampaignData,
          template_id: action?.templateId,
          custom_template_message: action?.custom_template_message,
          template_lang_code: action?.template_lang_code,
          template_name: action?.template_name,
          merge_variables_header: [],
          merge_variables_body: [],
        },
      };
    }

    case CLEAR_NEW_CAMPAIGN_TEMPLATE: {
      return {
        ...state,
        newCampaignData: {
          ...state?.newCampaignData,
          project_id: "",
          name: "",
          campaign_type: "",
          target_date: "",
          status: "live",
          template_id: "",
          template_lang_code: "",
          custom_template_message: "",
          template_name: "",
          template_media_url: "",
          merge_variables_header: [],
          merge_variables_body: [],
          template_params: [],
          filter_rules: [],
          dateFilters: [],
          carousel_card: [],
          sample_code_variables: [],
        },
        campaignDetailsStats: {},
        campaignDetailsUserStats: [],
      };
    }

    case FETCH_CAMPAIGN_ID_STATS_DETAILS:
    case FETCH_CAMPAIGN_ID_STATS_FAILURE: {
      return {
        ...state,
        isCampaignDetailsStats: action.isFetchCampaignIdStats,
      };
    }

    case FETCH_CAMPAIGN_ID_STATS_DETAIL_SUCCESS: {
      return {
        ...state,
        isCampaignDetailsStats: action.isFetchCampaignIdStats,
        campaignDetailsStats: action.payload,
      };
    }

    case FETCH_CAMPAIGN_ID_STATS_USER_DETAILS:
    case FETCH_CAMPAIGN_ID_STATS_USER_FAILURE: {
      return {
        ...state,
        isCampaignDetailsUserStats: action.isCampaignDetailsUserStats,
      };
    }

    case FETCH_CAMPAIGN_ID_STATS_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        isCampaignDetailsUserStats: action.isCampaignDetailsUserStats,
        campaignDetailsUserStats: action.payload,
      };
    }

    case UPDATE_INITIAL_DATA: {
      return {
        ...state,
        pageNum: action.keyName === "pageNum" ? action.keyValue : state.pageNum,
        pageSize: action.keyName === "pageSize" ? action.keyValue : state.pageSize,
        start_date: action.keyName === "start_date" ? action.keyValue : state.start_date,
        end_date: action.keyName === "end_date" ? action.keyValue : state.end_date,
      };
    }
    case FETCH_CAMPAIGN_CUSTOMER_COUNT: {
      return {
        ...state,
        campaignCustomerCount: {
          ...state.campaignCustomerCount,
          ...action.payload,
        },
      };
    }

    case UPDATE_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }

    case SEND_MESSAGE_REQUEST:
      return { ...state, sending: true, error: null };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sending: false,
        response: action.payload,
        error: null,
      };
    case SEND_MESSAGE_FAILURE:
      return { ...state, sending: false, error: action.payload };

    case FETCH_CAMPAIGN_LIST_EXCEL:
      return {
        ...state,
        excelCampaignListData: {
          ...state.excelCampaignListData,
          status: action.status,
        },
      };
    case FETCH_CAMPAIGN_LIST_EXCEL_SUCCESS:
      return {
        ...state,
        excelCampaignListData: {
          ...state.excelCampaignListData,
          status: action.status,
          data: [...action.data],
        },
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default campaignReducer;
