import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import TextInput from "konnekt/TextInput";
import Accordion from "konnekt/Accordion";
import Icon from "konnekt/Icon";
import Button from "konnekt/Buttons";
import DropDownControlled from "konnekt/Dropdown";
import dashboardData from "../../../../constants/newConstants/dashboardData.json";
import Modal, { ModalHeader } from "konnekt/Modal";
import { updateProjectUserDetails } from "modules/App/AppActions";
import { showNotification } from "util/utility";
import { getActiveProject, getActiveProjectId, getProjectId, getProjectList, getProjectUpdatingState } from "modules/App/AppSelectors";
import isEmpty from "lodash/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ChangePlan from "modules/Manage/pages/Billing/Component/ChangePlan";
import {
  addNewBillingaddress,
  billingAddressUpdate,
  fetchBillingaddressList,
  getActiveSubscriptionList,
  getBillingPlansList,
  phoneNumberQualityRating,
} from "modules/Manage/ManagePageAction";
import BillingShipping from "modules/Manage/pages/Billing/Component/BillingShipping";
import UpdateBilling from "modules/Manage/pages/Billing/Component/UpdateBilling";
import UpdateShipping from "modules/Manage/pages/Billing/Component/UpdateShipping";
import { BuyMoreModalKey } from "modules/Manage/pages/Billing/Component/constants";
import BuyMoreModal from "modules/Manage/pages/Billing/Component/BuyMoreModal";
import ReactTooltip from "react-tooltip";
import { INPUT_VALIDATION_ALLOWED_KEYS } from "../../../../constants/inputValidationAllowedKeys";
import InputValidationWrapper from "konnekt/InputValidationWrapper";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../konnekt/ErrorMessage";
import {
  DashboardBody,
  DashboardPlatformBodyItem,
  DashboardPlatformTitles,
  DashboardPlatformItem,
  StyledColEight,
  StyledDashboard,
  HeaderWhatsAppBody,
  HeaderBold,
  ImgContainer,
  BoardRight,
  BusinessAccountBody,
  BusinessAccountHading,
  AccordionBoldHeading,
  AccordionBoldTitle,
  AccordionItemTitle,
  StyledColFour,
  StyledEducation,
  StyledNumber,
  StyledUserData,
  TittleWithOutBold,
  HideProfileHading,
  HideProfileParagraph,
  CurrentPlanBody,
  CurrentPlanItem,
  CurrentPlanItemLabel,
  CurrentPlanMonthlyBody,
  CustomizeBody,
  Label1982,
  LabelMonthly,
  ModalBody,
  PlatformTutorial,
  ProfileDetails,
  ProfileDetailsInput,
  VerticalDescription,
  TitleName,
} from "./dashboard.styles";
import { FlexCenterGap } from "CommonStyle/CommonStyles";
import useFacebookSDK from "customHooks/useFacebooksdk";
let projectId = "";
let businessId = "";
const API_URL = process.env.REACT_APP_API_URL;

const modalDetail = ["description", "address", "email", "website"];

const DashboardPage = ({ mandatoryStepsPanelIndex, openNextRequiredStep, setMandatoryStepsPanelIndex, onHide }) => {
  const dispatch = useDispatch();
  const isSDKLoaded = useFacebookSDK("2328599353932616");

  const activeProject = useSelector((state) => getActiveProject(state));
  const activeProjectId = useSelector((state) => getActiveProjectId(state));
  const projectList = useSelector((state) => getProjectList(state));
  const activeBusinessId = useSelector((state) => state.app.user.business_id);
  projectId = activeProjectId;
  businessId = activeBusinessId;
  const credit = activeProject?.credit;
  const isUpdatingProject = useSelector((state) => getProjectUpdatingState(state));
  const { qualityRating } = useSelector((state) => state?.manage?.qualityRatings);
  const activeSubscriptionList = useSelector((state) => state.manage?.activeSubscriptionList);
  const activeSubscription = useSelector((state) => state.manage?.activeSubscriptionList[0]);
  const billingAddressList = useSelector((state) => state.manage?.billingAddressList?.addressList);
  const shippingAddressList = useSelector((state) => state.manage?.shippingAddressList?.addressList);
  const activePlan = useSelector((state) => state.manage?.getPlansList?.activePlansList?.filter((plan) => plan._id === activeSubscription?.current_plan_id)[0]);

  function download(filename, text) {
    var blob = new Blob([text], { type: "text/plain" });
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  }

  const fbLoginButton = () => {
    if (!isSDKLoaded) return;

    window.FB.login(
      (response) => {
        //download("facebook_response", JSON.stringify(response));
        if (response?.authResponse) {
          let payload = {
            business_id: businessId,
            project_id: projectId,
            code: response?.authResponse?.code,
            status: response?.status,
          };
          fetch(`${API_URL}/auth/facebook_auth/signup`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
          // .then((response) => response.json())
          // .then((data) => {
          //   // Handle the response from your API
          //   console.log(data);
          // })
          // .catch((error) => {
          //   console.error("Error:", error);
          // });
        }
      },
      {
        config_id: "7045071175562639", // your configuration ID
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {
            solutionID: "938926017833634",
          },
        },
      }
    );
  };

  useEffect(() => {
    if (window) {
      window.fbAsyncInit = () => {
        FB.init({
          appId: "2328599353932616",
          cookie: true,
          xfbml: true,
          version: "v9.0",
          status: true,
        });
      };
    }

    // Load the JavaScript SDK asynchronously
    (function (d) {
      var js,
        id = "facebook-jssdk",
        ref = d.getElementsByTagName("script")[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement("script");
      js.id = id;
      js.async = true;
      js.src = "//connect.facebook.net/en_US/all.js";
      ref.parentNode.insertBefore(js, ref);
    })(document);
  }, []);

  useEffect(() => {
    console.log("Is SDK loaded (new method):", isSDKLoaded);
    if (isSDKLoaded) {
      console.log("FB object (new method):", window.FB);
    }
  }, [isSDKLoaded]);

  useEffect(() => {
    if (activeProjectId) dispatch(getBillingPlansList());
    if (activeProjectId) dispatch(fetchBillingaddressList(activeProjectId, "billing"));
    if (activeProjectId) dispatch(fetchBillingaddressList(activeProjectId, "shipping"));
    if (activeProjectId) dispatch(getActiveSubscriptionList(activeProjectId));
  }, [activeProjectId]);

  useEffect(() => {
    if (!updateBillingDetails && billingAddressList) {
      setUpdateBillingDetails(billingAddressList[0]);
    }
  }, [billingAddressList]);

  useEffect(() => {
    if (!shippingDetails && shippingAddressList) {
      setShippingDetails(shippingAddressList[0]);
    }
  }, [shippingAddressList]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [hideProfile, setHideProfile] = useState(false);
  const [whatsAppBusinessAccount, setWhatsAppBusinessAccount] = useState(true);
  const [platformTutorial, setPlatformTutorial] = useState(true);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [modalProjectData, setModalProjectData] = useState({});
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [modalWidth, setModalWidth] = useState("600px");
  const [modalHeight, setModalHeight] = useState("85%");
  const [changeDropDown, setChangeDropDown] = useState({ value: "" });
  const [amount, setAmount] = useState(5000);
  const [number, setNumber] = useState("7667695322");
  const [backBtn, setBackBtn] = useState("");
  const [billingBtnColor, setBillingBtnColor] = useState("Monthly");
  const [basicAmount, setBasicAmount] = useState("₹999/mo.");
  const [errors, setErrors] = useState({});
  const [disableManage, setDisableManage] = useState(true);
  const [backBtnBilling, setBackBtnBilling] = useState(false);
  const [updateBillingDetails, setUpdateBillingDetails] = useState(null);
  const [backBtnPayment, setBackBtnPayment] = useState(false);
  const [shippingDetails, setShippingDetails] = useState(null);

  useEffect(() => {
    if (!isEmpty(activeProject)) {
      setModalProjectData(activeProject);
    }
  }, [activeProject, openProfileModal]);

  useEffect(() => {
    if (activeProjectId) {
      dispatch(phoneNumberQualityRating(activeProjectId));
    }
  }, [activeProjectId]);

  const steps = (openNextRequiredStep, activeProject) => [
    {
      id: "1",
      title: dashboardData.business_api.label,
      iconName: "tickIcon",
      iconColor: "red",
      content: (
        <Box pl="55px" openNextRequiredStep={openNextRequiredStep}>
          <AccordionItemTitle>{dashboardData.business_api.text}</AccordionItemTitle>
          {dashboardData.business_api.list.map((item, i) => (
            <AccordionItemTitle key={i}>{item}</AccordionItemTitle>
          ))}
          <AccordionBoldTitle>{dashboardData.business_api.url}</AccordionBoldTitle>
          <div style={{ display: "flex" }}>
            {activeProject?.business_api_key ? (
              <Icon iconName="greenTick" color="var(--green)" />
            ) : (
              <Icon height="20" width="20" iconName="cancelled" color="red" />
            )}
            <AccordionBoldTitle style={{ marginLeft: 10 }}>{dashboardData.business_api.res}</AccordionBoldTitle>
          </div>
        </Box>
      ),
    },
    {
      id: "2",
      title: dashboardData.api_key.label,
      iconName: "keyIcon",
      content: (
        <Box pl="55px" openNextRequiredStep={openNextRequiredStep}>
          <AccordionItemTitle>{dashboardData.api_key.text}</AccordionItemTitle>
          <Box display="flex">
            {activeProject?.wa_api_key ? <Icon iconName="greenTick" color="var(--green)" /> : <Icon height="20" width="20" iconName="cancelled" color="red" />}
            {/* <Icon iconName="greenTick" color="var(--green)"/> */}
            <AccordionBoldTitle style={{ marginLeft: 10 }}>{dashboardData.api_key.res}</AccordionBoldTitle>
          </Box>
        </Box>
      ),
    },
    {
      id: "3",
      title: dashboardData.phone_name_verification.label,
      iconName: "whatsappLogo",
      content: (
        <Box pl="55px" openNextRequiredStep={openNextRequiredStep}>
          <AccordionItemTitle>{dashboardData.phone_name_verification.text}</AccordionItemTitle>
          <Box display="flex" justifyContent="space-between" width="40%">
            {dashboardData.phone_name_verification.url.map((item, i) => (
              <AccordionBoldTitle key={i}>{item}</AccordionBoldTitle>
            ))}
          </Box>
          <Box display="flex">
            {activeProject?.name && activeProject?.phone_number ? (
              <Icon iconName="greenTick" color="var(--green)" />
            ) : (
              <Icon height="20" width="20" iconName="cancelled" color="red" />
            )}
            {/* <Icon iconName="greenTick" color="var(--green)"/> */}
            <AccordionBoldTitle style={{ marginLeft: 10 }}>{dashboardData.phone_name_verification.res}</AccordionBoldTitle>
          </Box>
        </Box>
      ),
    },
    {
      id: "4",
      title: dashboardData.fb_manager_verification.label,
      iconName: "facebookIcon",
      content: (
        <Box pl="55px" openNextRequiredStep={openNextRequiredStep}>
          {<Button onClick={fbLoginButton}>Connect to Facebook</Button>}
          <AccordionItemTitle>{dashboardData.fb_manager_verification.text}</AccordionItemTitle>
          <Box display="flex" justifyContent="space-between" width="40%">
            {dashboardData.fb_manager_verification.url.map((item, i) => (
              <AccordionBoldTitle key={i}>{item}</AccordionBoldTitle>
            ))}
          </Box>
          <Box display="flex">
            {activeProject?.fb_business_manager_status ? (
              <Icon iconName="greenTick" color="var(--green)" />
            ) : (
              <Icon height="20" width="20" iconName="cancelled" color="red" />
            )}

            {/* <Icon iconName="greenTick" color="var(--green)"/> */}
            <AccordionBoldTitle style={{ marginLeft: 10 }}>{dashboardData.fb_manager_verification.res}</AccordionBoldTitle>
          </Box>
        </Box>
      ),
    },
    {
      id: "5",
      title: dashboardData.setup_profile.label,
      iconName: "contactIcon",
      content: (
        <Box pl="55px" openNextRequiredStep={openNextRequiredStep}>
          <AccordionItemTitle>{dashboardData.setup_profile.text}</AccordionItemTitle>
          <Box display="flex" justifyContent="space-between" width="40%">
            {dashboardData.setup_profile.url.map((item, i) => (
              <AccordionBoldTitle key={i}>{item}</AccordionBoldTitle>
            ))}
          </Box>
          <Box display="flex">
            {activeProject?.wa_display_name && activeProject?.wa_phone_number ? (
              <Icon iconName="greenTick" color="var(--green)" />
            ) : (
              <Icon height="20" width="20" iconName="cancelled" color="red" />
            )}
            {/* <Icon iconName="greenTick" color="var(--green)"/> */}
            <AccordionBoldTitle style={{ marginLeft: 10 }}>{dashboardData.setup_profile.res}</AccordionBoldTitle>
          </Box>
        </Box>
      ),
    },
    {
      id: "6",
      title: dashboardData.apply_green_tick.label,
      iconName: "contactIcon",
      content: (
        <Box pl="55px" openNextRequiredStep={openNextRequiredStep}>
          <AccordionItemTitle>{dashboardData.apply_green_tick.label}</AccordionItemTitle>
          {dashboardData.apply_green_tick.list.map((item, i) => (
            <AccordionItemTitle key={i}>{item}</AccordionItemTitle>
          ))}
          {dashboardData.apply_green_tick.url.map((item, i) => (
            <AccordionBoldTitle key={i}>{item}</AccordionBoldTitle>
          ))}
          <div style={{ display: "flex" }}>
            {activeProject?.waba_id && activeProject?.wa_api_key && activeProject?.wa_display_name ? (
              <Icon iconName="greenTick" color="var(--green)" />
            ) : (
              <Icon height="20" width="20" iconName="cancelled" color="red" />
            )}

            {/* <Icon iconName="greenTick" color="var(--green)"/> */}
            <AccordionBoldTitle style={{ marginLeft: 10 }}>{dashboardData.apply_green_tick.res}</AccordionBoldTitle>
          </div>
        </Box>
      ),
    },
  ];

  const backPaymentHandler = () => {
    if (activeModal == "Payment Methods") {
      setActiveModal("Change Plan");
      setBackBtnPayment(false);
    }
    // if (activeModal == "Payment Method Details") {
    //   setActiveModal("Payment Methods");
    //   setModalWidth("400px");
    // }
    // if (activeModal == "Update your payment method") {
    //   setActiveModal("Payment Method Details");
    //   setModalWidth("400px");
    // }
    // if (activeModal == "Add a payment method") {
    //   setActiveModal("Payment Methods");
    //   setModalWidth("400px");
    // }
    if (activeModal == "Billing & Shipping Addresses") {
      setActiveModal("Change Plan");
      setBackBtnPayment(false);
    }
    if (activeModal == "Update your billing details") {
      setActiveModal("Billing & Shipping Addresses");
    }
    if (activeModal == "Update your shipping details") {
      setActiveModal("Billing & Shipping Addresses");
    }
  };

  const modalHandler = () => {
    setOpenProfileModal(false);
    setErrors({});
    setActiveModal("");
    setModalWidth("600px");
    setBackBtn("");
    setBasicAmount("₹999/mo.");
    setBackBtnBilling(false);
  };

  const editHandle = () => {
    setOpenProfileModal(true);
    setActiveModal("Profile Details");
  };

  const upgradeHandle = () => {
    setActiveModal("Change Plan");
    setOpenProfileModal(true);
    setBackBtnBilling(false);
  };

  const paymentConfirmationCallback = () => {
    dispatch(getActiveSubscriptionList(activeProjectId));
    setOpenProfileModal(false);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.logoUploadHandler(e.dataTransfer?.files?.[0]);
  };

  const handleValidation = (modalProjectData) => {
    let isValid = true;
    let err = {};
    if (!modalProjectData?.email || !isEmail(modalProjectData.email)) {
      isValid = false;
      err["email"] = "please enter valid email";
    }
    if (!modalProjectData?.address || modalProjectData?.address === "") {
      isValid = false;
      err["address"] = "please enter address";
    }
    if (!modalProjectData?.wa_business_website || modalProjectData?.wa_business_website === "") {
      isValid = false;
      err["website"] = "please enter website";
    }
    if (!modalProjectData?.description || modalProjectData?.description === "") {
      isValid = false;
      err["description"] = "please enter description";
    }
    return {
      isValid,
      err,
    };
  };
  const updateDetails = (keyName, keyValue, index) => {
    setErrors({});
    let update = { ...modalProjectData };
    if (keyName === "wa_business_website") {
      update[keyName][index] = keyValue;
    } else if (keyName === "remove") {
      update["wa_business_website"] = update?.wa_business_website?.filter((item, i) => i !== index);
    } else if (keyName === "removeProfile") {
      console.log("fun call");
      update["wa_business_logo_url"] = "";
    } else {
      update[keyName] = keyValue;
    }
    setModalProjectData(update);
    console.log(update);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        const updatedData = { ...modalProjectData };
        updatedData.wa_business_logo_url = newImageUrl;
        setModalProjectData(updatedData);
      };
      reader.readAsDataURL(file);
    }
  };

  const addUpdateAddressHandler = () => {
    setOpenProfileModal(false);
    const addressListDetails = { ...updateBillingDetails };

    if (billingAddressList?.length) {
      dispatch(billingAddressUpdate(activeProjectId, addressListDetails, "billing"));
    } else {
      dispatch(addNewBillingaddress(activeProjectId, addressListDetails, "billing"));
    }
  };

  const updateShippingAddressHandler = () => {
    setOpenProfileModal(false);
    const shippingAddressListDetails = { ...shippingDetails };
    if (shippingAddressList?.length) {
      dispatch(billingAddressUpdate(activeProjectId, shippingAddressListDetails, "shipping"));
    } else {
      dispatch(addNewBillingaddress(activeProjectId, shippingAddressListDetails, "shipping"));
    }
  };

  const handleUpdate = (activeProjectId, modalProjectData) => {
    let validate = handleValidation(modalProjectData);
    if (validate?.isValid) {
      dispatch(updateProjectUserDetails(activeProjectId, modalProjectData));
      setOpenProfileModal(false);
    } else {
      setErrors(validate?.err);
    }
  };

  const handleCancel = () => {
    setOpenProfileModal(false);
    setErrors({});
  };
  const buyMoreHandle = () => {
    setOpenProfileModal(true);
    setActiveModal(BuyMoreModalKey);
    setModalHeight("");
  };

  const modalEditHandle = () => {
    setActiveModal("Billing & Shipping Addresses");
    setOpenProfileModal(true);
    setModalWidth("400px");
    setModalHeight("");
    setBackBtnBilling(true);
  };
  const forwardHandle = (keyName) => {
    if (keyName === "Update your billing details") {
      setActiveModal("Update your billing details");
      setOpenProfileModal(true);
      setModalWidth("600px");
      setModalHeight("98%");
      setBackBtn(keyName);
      setBackBtnBilling(false);
    }
    if (keyName === "Update your shipping details") {
      setActiveModal("Update your shipping details");
      setOpenProfileModal(true);
      setModalWidth("600px");
      setModalHeight("98%");
      setBackBtn(keyName);
      setBackBtnBilling(false);
    }
  };
  const backHandler = () => {
    setActiveModal("Billing & Shipping Addresses");
    setBackBtn("");
    setModalWidth("400px");
    setModalHeight("");
    setBackBtnBilling(true);
  };
  const backBillingHandler = () => {
    setActiveModal("Change Plan");
    setModalWidth("600px");
  };
  const chooseBillingHandler = (keyName) => {
    if (keyName === "Monthly") {
      setBillingBtnColor(keyName);
      setBasicAmount("₹999/mo.");
      setChangeDropDown({ value: "2000 Monthly Active user" });
      setDisableManage(true);
    }
    if (keyName === "Yearly") {
      setBillingBtnColor(keyName);
      setBasicAmount("₹10789/yr.");
      setDisableManage(false);
    }
  };
  const placeOrderHandle = () => {
    alert("order placed");
  };
  const BillingUpdateHandler = (keyName, value) => {
    const billingUpdate = { ...updateBillingDetails };
    billingUpdate[keyName] = value;
    setUpdateBillingDetails(billingUpdate);
  };
  const shippingUpdateHandler = (keyName, value) => {
    const shippingUpdate = { ...shippingDetails };
    shippingUpdate[keyName] = value;
    setShippingDetails(shippingUpdate);
  };

  return (
    <>
      <StyledDashboard>
        <StyledColEight>
          <HeaderWhatsAppBody>
            <ul>
              <li>
                {dashboardData.status.businessAPIText}
                <Label color="var(--green)" fontWeight="600" fontSize="2rem" paddingTop="5px">
                  {activeProject?.whatsapp_api_status ? activeProject?.whatsapp_api_status : "-"}
                </Label>
              </li>
              <BoardRight></BoardRight>
              <hr />
              <li>
                {dashboardData.status.qualityRatingText}
                <Label color="#5E3EC5" fontWeight="600" fontSize="2rem" paddingTop="5px">
                  {qualityRating}
                </Label>
              </li>
              <BoardRight></BoardRight>
              <hr />
              <li>
                {dashboardData.status.remainingQuotaText}
                <Label color="#5E3EC5" fontWeight="600" fontSize="2rem" paddingTop="5px">
                  {activeSubscription?.fixed_plan_credits
                    ? activeSubscription?.fixed_plan_credits + activeSubscription?.extra_credits - activeSubscription?.used_credits
                    : "-"}
                </Label>
              </li>
            </ul>
          </HeaderWhatsAppBody>
          <BusinessAccountBody>
            <Box display="flex" justifyContent="space-between" pl="2rem" pr="2rem">
              <BusinessAccountHading>
                <Box display="flex">
                  <Icon iconName="whatsappLogo" color="var(--green)" />
                  <AccordionBoldHeading>Setup FREE WhatsApp Business Account</AccordionBoldHeading>
                </Box>
              </BusinessAccountHading>
              <Box display="flex" onClick={() => setWhatsAppBusinessAccount(!whatsAppBusinessAccount)}>
                <h1 style={{ fontWeight: 500, fontSize: 14, color: "#5E3EC5" }}>All Step</h1>
                <Icon
                  iconName="arrowBottom"
                  color="#5E3EC5"
                  width="8px"
                  height="1.8rem"
                  ml="10px"
                  transform={whatsAppBusinessAccount ? "rotate(-180deg)" : "none"}
                />
              </Box>
            </Box>
            <Box display={whatsAppBusinessAccount ? "block" : "none"}>
              <h1
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: "var(--green)",
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                ALL Done
              </h1>
              {/* <Label fontWeight="600" fontSize="1.6rem" color="#5E3EC5" pl="2rem">
               ALL Done
               </Label> */}
              <Accordion
                accordionData={steps(openNextRequiredStep)}
                variant="joint"
                withIcon
                isControlled
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            </Box>
          </BusinessAccountBody>
          <DashboardBody>
            <Box>
              <PlatformTutorial>
                <h1 style={{ fontWeight: 600, fontSize: 14 }}>Platform Walkthrough & Tutorials</h1>
                <Box display="flex" onClick={() => setPlatformTutorial(!platformTutorial)}>
                  <h1 style={{ fontWeight: 500, fontSize: 14, color: "#5E3EC5" }}>Explore Tutorials</h1>
                  <Icon
                    iconName="arrowBottom"
                    color="#5E3EC5"
                    width="8px"
                    height="1.8rem"
                    ml="10px"
                    transform={platformTutorial ? "rotate(-180deg)" : "none"}
                  />
                </Box>
              </PlatformTutorial>
              <DashboardPlatformBodyItem platformTutorial={platformTutorial}>
                <DashboardPlatformItem>
                  <img style={{ borderRadius: 18, maxHeight: "10rem" }} src="/images/tutorial.png" alt="Tutorial Image" />
                  <DashboardPlatformTitles>
                    <Icon iconName="openBook" mt=".3rem" mr="10px" />
                    <h1 style={{ fontSize: 16, color: "#AAAAAA" }}>Read Platform Guide</h1>
                  </DashboardPlatformTitles>
                </DashboardPlatformItem>
              </DashboardPlatformBodyItem>
            </Box>
          </DashboardBody>
        </StyledColEight>
        <StyledColFour>
          <StyledUserData>
            <StyledEducation mt="0px !important">
              <Box>
                <HeaderBold>{activeProject?.name || "-"}</HeaderBold>
                <TittleWithOutBold>{activeProject?.description || "-"}</TittleWithOutBold>
              </Box>
              <Icon
                iconName="pencilIcon"
                onClick={() => {
                  editHandle();
                }}
              ></Icon>
            </StyledEducation>
            <StyledNumber>
              <Box>
                <HeaderBold>{activeProject?.wa_phone_number || "-"}</HeaderBold>
                {activeProject?.wa_phone_number && (
                  <Box display="flex" style={{ alignItems: "center" }}>
                    <TittleWithOutBold style={{ marginBottom: 0 }}>
                      <a style={{ textDecoration: "unset" }} href={`https://wa.me/send?phone=${activeProject?.wa_phone_number}&text=Hello,`} target="_blank">
                        {`wa.me/${activeProject?.wa_phone_number}`}
                      </a>
                    </TittleWithOutBold>
                    <Box ml="5px">
                      <CopyToClipboard
                        text={`https://wa.me/send?phone=${activeProject?.wa_phone_number}&text=Hello,`}
                        onCopy={() => showNotification("success", "Copied")}
                      >
                        <Icon iconName="duplicate" color="#000" />
                      </CopyToClipboard>
                    </Box>
                  </Box>
                )}
              </Box>
              <ImgContainer>
                {console.log(activeProject)}
                <img src={activeProject?.wa_business_logo_url} />
              </ImgContainer>
            </StyledNumber>
            <Box display={hideProfile ? "none" : "block"}>
              <Box display="flex" width="100%" position="relative" left="64%" onClick={() => setHideProfile(true)}>
                <h6
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#5E3EC5",
                    letterSpacing: ".01rem",
                  }}
                >
                  View Profile
                </h6>
                <Icon iconName="arrowBottom" color="#5E3EC5" width="8px" height="1.8rem" ml="10px" />
              </Box>
            </Box>
            <Box display={hideProfile ? "block" : "none"}>
              <Box display="flex" width="100%" position="relative" left="64%" top="8px" mb="1.5rem" onClick={() => setHideProfile(false)}>
                <h1 style={{ fontWeight: 500, fontSize: 14, color: "#5E3EC5" }}>Hide Profile</h1>
                <Icon iconName="downArrow" color="#5E3EC5" width="8px" height="1.8rem" ml="10px" />
              </Box>
              <Box borderTop="1px solid #F3F3F3">
                <Box mt="1.5rem">
                  {modalDetail.map((item) => {
                    return (
                      <Box key={item}>
                        <HideProfileHading>{item}</HideProfileHading>
                        <HideProfileParagraph>{modalProjectData[item] ? modalProjectData[item] : "NA"}</HideProfileParagraph>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </StyledUserData>
          <StyledUserData>
            <FlexCenterGap style={{ justifyContent: "space-between" }}>
              <HeaderBold>Your Credit Amount </HeaderBold>
              <HeaderBold style={{ color: "var(--green)", alignItems: "center" }}>₹ {credit && credit?.toFixed(2)}</HeaderBold>
            </FlexCenterGap>
            <Box fontSize="1.2rem" mt="1rem">
              <strong>Note-</strong>When your credit balance reaches 0, you won't be able to use features such as sending messages, running campaigns, or
              configuring welcome messages etc.
            </Box>
          </StyledUserData>
          <StyledUserData>
            <HeaderBold>What’s Conversation Credits (WCC)</HeaderBold>
            <StyledEducation>
              <HeaderBold style={{ color: "var(--green)", alignItems: "center" }}>₹ {activeSubscription?.extra_credits}</HeaderBold>
              <Button type="primary" onClick={buyMoreHandle}>
                Buy More
              </Button>
            </StyledEducation>
          </StyledUserData>
          <CurrentPlanBody>
            <HeaderBold>Current Plan</HeaderBold>
            <CurrentPlanItem>
              <CurrentPlanItemLabel>
                <HeaderBold>{activePlan?.name || "-"}</HeaderBold>
                {activePlan?.plan_type && (
                  <HideProfileParagraph style={{ position: "relative", top: 5, marginLeft: 5 }}>({activePlan?.plan_type})</HideProfileParagraph>
                )}
              </CurrentPlanItemLabel>
              <HideProfileParagraph style={{ position: "relative", top: 5 }}>Renews on 25-03-2023</HideProfileParagraph>
            </CurrentPlanItem>
            <CurrentPlanMonthlyBody>
              <LabelMonthly>Monthly Active Users (MAU) Left</LabelMonthly>
              <Label1982 color="var(--black)">1982</Label1982>
            </CurrentPlanMonthlyBody>

            <hr
              style={{
                background: "#5E3EC5",
                color: "#5E3EC5",
                borderColor: "#5E3EC5",
                height: ".3rem",
              }}
            />

            <Button type="primary" margin="auto" onClick={upgradeHandle}>
              upgrade
            </Button>
          </CurrentPlanBody>
          <CustomizeBody>
            <Box style={{ flex: 1 }}>
              <HeaderBold>Customize WhatsApp Link</HeaderBold>
              <TittleWithOutBold>Create shareable links & QR for your WA business number</TittleWithOutBold>
              <Label fontSize="1.2rem" color="#5E3EC5">
                View More &gt;&gt;
              </Label>
            </Box>
            <img width="79px" height="76px" src="/images/dash-img.png" alt="Image" />
          </CustomizeBody>
          <CustomizeBody>
            <Box style={{ flex: 1 }}>
              <HeaderBold color="#000000" fontWeight="500" fontSize="1.6rem">
                WhatsApp Website Button
              </HeaderBold>
              <TittleWithOutBold fontSize="1.2rem" mr="10px">
                Drive WhatsApp sales with personalised CTAs
              </TittleWithOutBold>
              <Label fontSize="1.2rem" color="#5E3EC5">
                View More &gt;&gt;
              </Label>
            </Box>
            <img width="79px" height="76px" src="/images/dash-img2.png" alt="Image" />
          </CustomizeBody>
        </StyledColFour>
        <Modal
          isOpen={openProfileModal}
          onHide={modalHandler}
          pl="24"
          pr="24"
          maxWidth={modalWidth}
          height={modalHeight}
          pb="2.4rem"
          style={{ overflowY: "auto" }}
        >
          <ModalHeader variant="white" font={activeModal === BuyMoreModalKey ? "small-m" : ""} onHide={modalHandler} padding="0">
            <Box display="flex" alignItems="center">
              {backBtn && (
                <Box mr="10">
                  <Icon iconName="back" onClick={backHandler} />
                </Box>
              )}
              {backBtnBilling && (
                <Box mr="10">
                  <Icon iconName="back" onClick={backBillingHandler} />
                </Box>
              )}
              {activeModal}
            </Box>
          </ModalHeader>
          <ModalBody height={modalHeight}>
            <Box>
              {activeModal === BuyMoreModalKey && (
                <BuyMoreModal billingDetails={updateBillingDetails} paymentConfirmationCallback={paymentConfirmationCallback} />
              )}
            </Box>
            {activeModal === "Profile Details" && (
              <Box>
                <Box display="flex" alignItems="center"></Box>
                <Box>
                  <Label variant="text4" mt="10px">
                    Profile Picture
                  </Label>
                  <div>
                    Max size of 5MB allowed.
                    <br />
                    Image size of 640x640 is recommended.
                    <br />
                    Images with a height or width of less than 192px may cause issues.
                  </div>
                  <FlexCenterGap mt="10px" gap="1rem" display="flex">
                    {modalProjectData?.wa_business_logo_url && (
                      <ImgContainer>
                        <img src={modalProjectData?.wa_business_logo_url} />
                      </ImgContainer>
                    )}

                    <Button type="primary" fontSize="1rem" position="relative">
                      <input type="file" accept=".png, .jpg, .jpeg" className="upload-input" onChange={(e) => handleImageChange(e)} />
                      Change
                    </Button>

                    <Button type="outlineColor" fontSize="1rem" onClick={() => updateDetails("removeProfile", null, null)}>
                      Remove
                    </Button>
                  </FlexCenterGap>
                </Box>
                <ProfileDetails>
                  <ProfileDetailsInput>
                    <Box mt="25px">
                      <Label variant="text4">Description</Label>

                      <div display="inlineblock" mt="8px">
                        Description of the business.
                        <br />
                        Maximum of 256 characters.
                      </div>
                    </Box>

                    <TextInput
                      type="text"
                      bodyTextLimit={256}
                      height="4rem"
                      onChange={(e) => updateDetails("description", e)}
                      value={modalProjectData?.description}
                      border="1px solid rgba(0,0,0,.08)"
                      placeholder="Enter description first"
                      backgroundColor="#f0f0f0"
                      inputBg="#f0f0f0"
                    />
                    {errors?.description && <ErrorMessage>{errors?.description}</ErrorMessage>}
                    <TitleName>
                      <Label variant="text4">Enter email</Label>
                      <div>
                        Email address (in valid email format) to contact the business.
                        <br /> Maximum of 128 characters.
                      </div>
                    </TitleName>
                    <InputValidationWrapper allowedKeys={INPUT_VALIDATION_ALLOWED_KEYS.email}>
                      {(ref) => (
                        <input
                          ref={ref}
                          type="text"
                          onChange={(e) => updateDetails("email", e.target.value)}
                          backgroundColour={"red"}
                          value={modalProjectData?.email}
                          width="50%"
                          bodyTextLimit={24}
                          placeholder="Email"
                          style={{
                            borderRadius: "6px",
                            border: "1px solid rgba(0,0,0,.08)",
                            backgroundColor: "#f0f0f0",
                          }}
                          height="44px"
                          mt="10px"
                        />
                      )}
                    </InputValidationWrapper>
                    {errors?.email && <ErrorMessage>{errors?.email}</ErrorMessage>}
                    <Box mt="10px" mb="10px">
                      <Box display="flex" alignItems="center">
                        <Label variant="text4">Enter website</Label>
                        <Box data-tip data-for="website">
                          <Icon cursor="pointer" iconName="infoOutlined" width="1.4rem" height="1.4rem" maxWidth="400px" color="#0D14C0" ml="8px" />
                        </Box>
                        <ReactTooltip className="tooltip special-tooltip" multiline id="website" place="bottom" effect="solid">
                          <p className="kiwi-text6">
                            URLs (including http:// or https://) associated with the business (e.g., website, Facebook Page, Instagram). Maximum of 2 websites
                            with a maximum of 256 characters each.
                          </p>
                        </ReactTooltip>
                      </Box>
                      {!modalProjectData?.wa_business_website?.length && (
                        <Button
                          mt="10px"
                          width="fit-content"
                          height="37px"
                          type="outlineColor"
                          onClick={() => updateDetails("wa_business_website", "", modalProjectData?.wa_business_website?.length)}
                        >
                          Add Website
                        </Button>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" width="100%" flexWrap="wrap">
                      {modalProjectData?.wa_business_website?.map((item, index) => {
                        return (
                          <Box display="flex" alignItems="center" mb="8px" width="100%" key={index}>
                            <input
                              type="text"
                              onChange={(e) => updateDetails("wa_business_website", e.target.value, index)}
                              value={item}
                              width="85%"
                              placeholder="website"
                              height="44px"
                              style={{
                                borderRadius: "6px",
                                border: "1px solid rgba(0,0,0,.08)",
                                backgroundColor: "#f0f0f0",
                              }}
                            />
                            {modalProjectData?.wa_business_website?.length === 2 && (
                              <Icon onClick={() => updateDetails("remove", "", index)} iconName="minus" ml="5px" cursor="pointer" />
                            )}
                            {modalProjectData?.wa_business_website?.length === 1 && (
                              <Icon onClick={() => updateDetails("wa_business_website", "", index + 1)} iconName="plus" ml="5px" cursor="pointer" />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                    {errors?.website && <ErrorMessage>{errors?.website}</ErrorMessage>}
                    {/* </Box> */}
                  </ProfileDetailsInput>
                  <ProfileDetailsInput>
                    <TitleName>
                      <Label variant="text4">Address</Label>
                    </TitleName>
                    <div>
                      Address of the business.
                      <br />
                      Maximum of 256 characters.
                    </div>
                    <TextInput
                      type="text"
                      onChange={(e) => updateDetails("address", e)}
                      value={modalProjectData?.address}
                      bodyTextLimit={256}
                      placeholder="enter address"
                      height="40px"
                      border="1px solid rgba(0,0,0,.08)"
                      backgroundColor="#f0f0f0"
                      inputBg="#f0f0f0"
                    />
                    {errors?.address && <ErrorMessage>{errors?.address}</ErrorMessage>}
                    <TitleName>
                      <Label variant="text4">Vertical</Label>
                      <VerticalDescription>Industry of the business.</VerticalDescription>
                      <DropDownControlled
                        width="100%"
                        selectedValue={"None"}
                        style={{
                          backgroundColor: "#f0f0f0",
                          borderRadius: "6px",
                          border: "1px solid rgba(0,0,0,.08)",
                          height: "4rem",
                        }}
                        // onOptionClick={(item) =>
                        //   props.handleChange("wa_business_category", item.value)
                        // }
                        options={[
                          {
                            label: "Automotive",
                            value: "Automotive",
                          },
                          {
                            label: "Beauty, Spa and Salon",
                            value: "Beauty, Spa and Salon",
                          },
                          {
                            label: "Clothing and Apparel",
                            value: "Clothing and Apparel",
                          },
                          {
                            label: "Education",
                            value: "Education",
                          },
                          {
                            label: "Entertainment",
                            value: "Entertainment",
                          },
                          {
                            label: "Event Planning and Service",
                            value: "Event Planning and Service",
                          },
                          {
                            label: "Finance and Banking",
                            value: "Finance and Banking",
                          },
                          {
                            label: "Food and Grocery",
                            value: "Food and Grocery",
                          },
                          {
                            label: "Public Service",
                            value: "Public Service",
                          },
                          {
                            label: "Hotel and Lodging",
                            value: "Hotel and Lodging",
                          },
                          {
                            label: "Medical and Health",
                            value: "Medical and Health",
                          },
                          {
                            label: "Non-profit",
                            value: "Non-profit",
                          },
                          {
                            label: "Professional Services",
                            value: "Professional Services",
                          },
                          {
                            label: "Shopping and Retail",
                            value: "Shopping and Retail",
                          },
                          {
                            label: "Travel and Transportation",
                            value: "Travel and Transportation",
                          },
                          {
                            label: "Restaurant",
                            value: "Restaurant",
                          },
                          {
                            label: "Other",
                            value: "Other",
                          },
                        ]}
                      />
                    </TitleName>
                  </ProfileDetailsInput>
                </ProfileDetails>
                <Box position="relative" height="44px" mt="10px">
                  <Box position="absolute" right="0px" display="flex">
                    <Button mr="10px" width="8rem" height="37px" type="outlineColor" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button disabled={isUpdatingProject} width="8rem" height="37px" onClick={() => handleUpdate(activeProject?._id, modalProjectData)}>
                      {isUpdatingProject ? "Saving..." : "Save"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {activeModal === "Change Plan" && (
              <ChangePlan
                activeProjectId={activeProjectId}
                modalEditHandle={modalEditHandle}
                placeOrderHandle={placeOrderHandle}
                updateBillingDetails={updateBillingDetails}
                setBackBtnPayment={setBackBtnPayment}
                paymentConfirmationCallback={paymentConfirmationCallback}
              />
            )}
            {activeModal === "Billing & Shipping Addresses" && (
              <BillingShipping
                updateBillingDetails={billingAddressList ? billingAddressList[0] : {}}
                shippingDetails={shippingAddressList ? shippingAddressList[0] : {}}
                forwardHandle={forwardHandle}
                setBackBtnPayment={setBackBtnPayment}
                setBackBtnBilling={setBackBtnBilling}
              />
            )}
            {activeModal === "Update your billing details" && (
              <UpdateBilling
                updateBillingDetails={updateBillingDetails}
                BillingUpdateHandler={BillingUpdateHandler}
                addUpdateAddressHandler={addUpdateAddressHandler}
                billingAddressList={billingAddressList}
              />
            )}
            {activeModal === "Update your shipping details" && (
              <UpdateShipping
                shippingDetails={shippingDetails}
                shippingUpdateHandler={shippingUpdateHandler}
                updateShippingAddressHandler={updateShippingAddressHandler}
                shippingAddressList={shippingAddressList}
              />
            )}
          </ModalBody>
        </Modal>
      </StyledDashboard>
    </>
  );
};

export default DashboardPage;
